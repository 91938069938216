import React from 'react';
import { Layout } from 'antd';
import './index.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from 'routes/home';

const { Content: AntContent } = Layout;

const Content = () => {
  return (
    <AntContent className='app-content'>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </AntContent>
  );
};

export default Content;
import { Layout as AntLayout } from 'antd';
import './index.css';
import Header from './header';
import Content from './content';
import Footer from './footer';
import { useEffect, useState } from 'react';

const Layout = () => {
  
  const [showHeaderDark, setShowHeaderDark] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', scrollEvent, true);
    return () => {
      window.removeEventListener('scroll', scrollEvent);
    };
  }, []);

  const scrollEvent = () => {
    const _scrollTop = document.documentElement.scrollTop;
    if (_scrollTop > 0) {
      setShowHeaderDark(true);
    } else {
      setShowHeaderDark(false);
    }
  };
  // if (isMobile) {
  //   return (
  //     <AntLayout className='mobile-lauput-container'>
  //       {/* <div className='mobile-header-space' /> */}
  //       <HeaderMobile showHeaderDark={showHeaderDark} />
  //       <ContentMobile />
  //       <FooterMobile />
  //       <div className='mobile-fotter-space' />
  //       <FooterMobileDownload />
  //     </AntLayout>
  //   );
  // }
  return (
    <AntLayout>
      {/* <div style={{
        color: 'black',
        height: '8.3rem'
      }} /> */}
      <Header showHeaderDark={showHeaderDark} />
      <Content />
      <Footer />
    </AntLayout>
  );
};

export default Layout;
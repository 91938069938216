
/* eslint-disable @typescript-eslint/no-var-requires */
export const HeadIconApple = require('@/assets/image/normal/icon_apple.png');
export const HeadIconGoogle = require('@/assets/image/normal/icon_google.png');
export const PicULogo = require('@/assets/image/normal/picu_logo.png');
export const Home1 = require('@/assets/image/home/home_1.png');
export const Home2 = require('@/assets/image/home/home_2.png');
export const Home3 = require('@/assets/image/home/home_3.png');
export const Home4 = require('@/assets/image/home/home_4.png');
export const Home5 = require('@/assets/image/home/home_5.png');
export const Home6 = require('@/assets/image/home/home_6.png');
export const Home7 = require('@/assets/image/home/home_7.png');
export const HomeP1 = require('@/assets/image/home/home_person_1.png');
export const HomeP2 = require('@/assets/image/home/home_person_2.png');
export const HomeP3 = require('@/assets/image/home/home_person_3.png');
export const HomeP4 = require('@/assets/image/home/home_person_4.png');
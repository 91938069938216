import React from 'react';
import { Layout } from 'antd';
import { getRem } from 'utils/util';
import { ranyBold, ranyRegular } from 'utils/fontFamily';
import { PicULogo } from 'utils/assetsImage';

const { Footer: AntFooter } = Layout;

const Footer = () => {
  const renderLogoAndDownload = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          // justifyContent: 'space-between'
        }}
      >
        <a
          rel='canonical'
          href={'/'}>
          <img
            src={PicULogo}
            alt="logo"
            style={{
              width: getRem(188),
              height: getRem(60),
              cursor: 'pointer'
            }}
          />
        </a>
        <span
          style={{
            marginBottom: getRem(164),
            marginTop: getRem(40),
            fontSize: getRem(24),
            width: getRem(403),
            // marginTop: getRem(106),
            color: '#000',
            fontFamily: ranyRegular
          }}
        >
          Pic Match, Chat Instantly
        </span>
        <span
          style={{
            fontSize: getRem(18),
            // marginTop: getRem(106),
            color: '#666666',
            fontFamily: ranyRegular
          }}
        >
          © 2024 PicU | All Rights Reserved
        </span>
      </div>
    );
  };

  const renderSupport = () => {
    const support = [
      {
        name: 'Support',
        link: undefined,
      },
      {
        name: 'FAQ',
        link: 'https://www.pic-u.com/faq.html',
      },
      {
        name: 'contactus@pic-u.com',
        link: '',
      },
    ];
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        // marginLeft: getRem(110)
      }}>
        {
          support.map((value, index) => {
            if (index == 0) {
              return <span
                key={support[0].name + index}
                style={{
                  marginBottom: getRem(34),
                  fontSize: getRem(20),
                  fontWeight: 'bold',
                  color: 'black',
                  fontFamily: ranyBold
                }}>{value.name}</span>;
            }
            if (value.link) {
              return (
                <a
                  key={support[0].name + index}
                  // rel='canonical'
                  href={value.link}
                  style={{
                    marginBottom: getRem(40),
                    fontSize: getRem(16),
                    color: '#000',
                    // width: getRem(180),
                    fontFamily: ranyRegular
                  }}
                >
                  {value.name}
                </a>
              );
            }
            return (
              <span
                key={support[0].name + index}
                // rel='canonical'
                style={{
                  marginBottom: getRem(40),
                  fontSize: getRem(16),
                  color: '#000',
                  // width: getRem(180),
                  fontFamily: ranyRegular
                }}
              >
                {value.name}
              </span>
            );
          })
        }
      </div>
    );
  };


  const renderLegal = () => {
    const legal = [
      {
        name: 'The legal stuff',
        link: undefined,
      },
      {
        name: 'Privacy Policy',
        link: 'https://www.pic-u.com/privacy-policy-PicU.html',
      },
      {
        name: 'Terms and Conditions',
        link: 'https://www.pic-u.com/Terms-and-Conditions-picu.html',
      },
    ];
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        // marginLeft: getRem(110)
      }}>
        {
          legal.map((value, index) => {
            if (index == 0) {
              return <span
                key={legal[0].name + index}
                style={{
                  marginBottom: getRem(34),
                  fontSize: getRem(20),
                  fontWeight: 'bold',
                  color: 'black',
                  fontFamily: ranyBold
                }}>{value.name}</span>;
            }
            if (value.link) {
              return (
                <a
                  key={legal[0].name + index}
                  // rel='canonical'
                  href={value.link}
                  style={{
                    marginBottom: getRem(40),
                    fontSize: getRem(16),
                    color: '#000',
                    // width: getRem(180),
                    fontFamily: ranyRegular
                  }}
                >
                  {value.name}
                </a>
              );
            }
            return (
              <span
                key={legal[0].name + index}
                // rel='canonical'
                style={{
                  marginBottom: getRem(40),
                  fontSize: getRem(16),
                  color: '#000',
                  // width: getRem(180),
                  fontFamily: ranyRegular
                }}
              >
                {value.name}
              </span>
            );
          })
        }
      </div>
    );
  };
  const renderGuidelines = () => {
    const guidelines = [
      {
        name: 'Guidelines',
        link: undefined,
      },
      {
        name: 'Community guidelines',
        link: 'https://www.pic-u.com/Community-guidelines-PicU.html',
      },
      {
        name: 'Security first',
        link: 'https://www.pic-u.com/Security-PicU.html',
      },
    ];
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        // marginLeft: getRem(110)
      }}>
        {
          guidelines.map((value, index) => {
            if (index == 0) {
              return <span
                key={guidelines[0].name + index}
                style={{
                  marginBottom: getRem(34),
                  fontSize: getRem(20),
                  fontWeight: 'bold',
                  color: 'black',
                  fontFamily: ranyBold
                }}>{value.name}</span>;
            }
            if (value.link) {
              return (
                <a
                  key={guidelines[0].name + index}
                  // rel='canonical'
                  href={value.link}
                  style={{
                    marginBottom: getRem(40),
                    fontSize: getRem(16),
                    color: '#000',
                    // width: getRem(180),
                    fontFamily: ranyRegular
                  }}
                >
                  {value.name}
                </a>
              );
            }
            return (
              <span
                key={guidelines[0].name + index}
                // rel='canonical'
                style={{
                  marginBottom: getRem(40),
                  fontSize: getRem(16),
                  color: '#000',
                  // width: getRem(180),
                  fontFamily: ranyRegular
                }}
              >
                {value.name}
              </span>
            );
          })
        }
      </div>
    );
  };


  return (
    <AntFooter
      style={{
        width: '100%',
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'row',
        // justifyContent: 'space-between',
        padding: `${getRem(84)} ${getRem(311)} ${getRem(113)} ${getRem(215)}`
      }}
    >
      {renderLogoAndDownload()}
      <div style={{ flex: 1 }} />
      {renderSupport()}
      <div style={{
        width: getRem(120)
      }} />
      {renderLegal()}
      <div style={{
        width: getRem(120)
      }} />
      {renderGuidelines()}
    </AntFooter>
  );
};

export default Footer;
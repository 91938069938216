// import { isAndroid, isMobile } from 'react-device-detect';

const downloadApp = (link: string) => {
  window.location.href = link;
};


const getRem = (px: number) => {
  return `${px / 16}rem`;
};

export {
  // downloadIosApp,
  // downloadAndroidApp,
  downloadApp,
  getRem,
};

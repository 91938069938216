import React, { useRef, useState } from 'react';
import './index.css';
import { HeadIconApple, HeadIconGoogle, Home1, Home2, Home3, Home4, Home5, Home6, Home7, HomeP1, HomeP2, HomeP3, HomeP4 } from 'utils/assetsImage';
import { downloadApp, getRem } from 'utils/util';
import { Carousel } from 'antd';
const homePersons = [HomeP1, HomeP2, HomeP3, HomeP4];
const Home = () => {
  const [currentIndex, setIndex] = useState(0);
  const swiperRef = useRef<any>();
  return (
    <div className="app-container">
      <img src={Home1} style={{
        width: getRem(1920),
        height: getRem(1172)
      }} alt="" />
      <img src={Home2} style={{
        width: getRem(1920),
        height: getRem(1140)
      }} alt="" />
      <img src={Home3} style={{
        width: getRem(1920),
        height: getRem(1150)
      }} alt="" />
      <img src={Home4} style={{
        width: getRem(1920),
        height: getRem(1150)
      }} alt="" />
      <img src={Home5} style={{
        width: getRem(1920),
        height: getRem(1183)
      }} alt="" />
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        width: getRem(1920),
        height: getRem(1300),
        alignItems: 'center',
        paddingTop: getRem(330),
        // justifyContent: 'center',
        backgroundImage: `url(${Home6})`,
        backgroundSize: '100%',
      }}>
        <Carousel
          ref={swiperRef}
          afterChange={(number) => {
            setIndex(number);
          }}
          style={{
            width: getRem(1560),
            height: getRem(696),
          }}
          dots={false}
          autoplay
        // dots={{
        //   className: 'carousel-dots',
        // }}
        >
          {
            homePersons.map((item, index) => <img className='home_person' key={'_home_person_' + index} src={item} />)
          }
        </Carousel>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
        }}>
          {
            homePersons.map((_, index) => <div
              key={'_home_person_dot' + index}
              onClick={() => {
                setIndex(index);
                try {
                  swiperRef.current.goTo(index);
                } catch (error) {
                  console.log('error');
                }
              }}
              style={{
                display: 'flex',
                width: getRem(360),
                height: getRem(10),
                borderRadius: getRem(7),
                cursor: 'pointer',
                marginRight: index < 3 ? getRem(10) : 0,
                backgroundColor: 'white',
                opacity: index != currentIndex ? 0.2 : 1
              }}
            />)
          }
        </div>
      </div>

      <div style={{
        display: 'flex',
        flexDirection: 'column',
        width: getRem(1920),
        height: getRem(524),
        backgroundImage: `url(${Home7})`,
        backgroundSize: '100%',
        paddingTop: getRem(115),
        paddingLeft: getRem(1316),
      }}>
        <div className='download-container'
          onClick={() => {
            // downloadApp('');
          }}
        >
          <img
            src={HeadIconApple}
            alt={'App Store'}
          />
          <span>App Store</span>
        </div>
        <div className='download-container'
          style={{
            marginTop: getRem(35)
          }}
          onClick={() => {
            downloadApp('https://play.google.com/store/apps/details?id=com.picu.app');
          }}
        >
          <img
            src={HeadIconGoogle}
            alt={'Google Play'}
          />
          <span>Google Play</span>
        </div>
      </div>
    </div>
  );
};

export default Home;
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { isMobile } from 'react-device-detect';
import Layout from 'layout';

if (isMobile) {
  document.body.style.minWidth = '320px';
} else {
  document.body.style.minWidth = '960px';
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

function setRootFontSize() {
  const screenWidth = window.innerWidth;
  // 根据屏幕宽度计算并设置根元素的字体大小
  const rootFontSize = screenWidth / (isMobile ? 46.875 : 120); //web端 1920/16 每120个像素为1rem，base 16px 可以根据需要进行调整  移动端750/16
  document.documentElement.style.fontSize = rootFontSize + 'px';
}
// 在窗口大小改变时调用设置根元素字体大小的函数
window.addEventListener('resize', setRootFontSize);
// 页面加载完成后初始化设置根元素字体大小
window.addEventListener('DOMContentLoaded', setRootFontSize);

root.render(
  <Layout />
);

import React from 'react';
import { Layout } from 'antd';
import './index.css';
// import useWindowSize from 'utils/useWindowSize';
// import { isMobile } from 'react-device-detect';
import { downloadApp, getRem } from 'utils/util';
import { HeadIconApple, HeadIconGoogle, PicULogo } from 'utils/assetsImage';
import { ranyRegular } from 'utils/fontFamily';

const { Header: AntHeader } = Layout;

interface HeaderProps {
  showHeaderDark: boolean;
}
const Header = (props: HeaderProps) => {
  // const {
  //   width,
  //   outerWidth,
  // } = useWindowSize();
  // let scale = 1;
  // if (isMobile) {
  //   scale = outerWidth / 960;
  // } else {
  //   scale = width / 960;
  //   scale = Math.max(1, scale);
  // }
  // const renderDownLoadApp = () => {
  //   const data = [{
  //     icon: require('@/assets/image/home/download_apple.png'),
  //     title: 'App Store',
  //     // textColor: 'rgba(0, 0, 0, 1)',
  //     backgroundColor: '#1A1A1A',
  //     // borderColor: 'rgba(0, 0, 0, 0)',
  //     // classname: 'header-download-appstore',
  //     type: 1,
  //   }, {
  //     icon: require('@/assets/image/home/download_google.png'),
  //     title: 'Google Play',
  //     // textColor: 'rgba(255, 255, 255, 1)',
  //     backgroundColor: '#1A1A1A',
  //     // borderColor: 'rgba(255, 255, 255, 1)',
  //     // classname: 'header-download-googleplay',
  //     type: 2,
  //   }];
  //   return (
  //     <div
  //       style={{
  //         top: 90 * scale,
  //       }}
  //     >
  //       {data.map((item, index) => (
  //         <div
  //           key={item.title}
  //           style={{
  //             marginLeft: index === 0 ? 0 : 13 * scale,
  //             width: 150 * scale,
  //             height: 42 * scale,
  //             backgroundColor: item.backgroundColor,
  //             borderRadius: 999,
  //           }}
  //           onClick={() => {
  //             if (item.type === 1) {
  //               // downloadIosApp();
  //             }
  //             else if (item.type === 2) {
  //               // downloadAndroidApp();
  //             }
  //           }}
  //         >
  //           <img
  //             style={{
  //               userSelect: 'none',
  //               width: 26 * scale,
  //               height: 26 * scale,
  //             }}
  //             title={item.title}
  //             src={item.icon}
  //             alt={item.title}
  //           />
  //         </div>
  //       ))}
  //     </div>
  //   );
  // };


  return (
    <AntHeader
      className='app-header'
      style={{
        padding: '4.25rem 7.25rem 4.25rem 13.77rem',
        // height: '8.3rem',
        backgroundColor: props.showHeaderDark ? 'transparent' : 'transparent',
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <div style={{
        display: 'flex',
        height: '100%',
        alignItems: 'center'
      }}>
        <div
          className="logo"
          style={{
            display: 'flex',
            width: '11.75rem',
            height: '3.73rem',
          }}
          onClick={() => {
            window.location.href = '/';
          }}
        >
          <img
            className='app-logo'
            src={PicULogo}
            alt="logo"
          />
        </div>
        <div
          className='navigation-container'
          style={{
            marginLeft: '3.75rem',
          }}
        >
          
        </div>
      </div>

      <div style={{
        display: 'flex',
        height: '100%',
        alignItems: 'center'
      }}>
        {/* <span style={{
          fontSize: '1.125rem',
          color: 'white',
          fontFamily: 'RanyBold',
        }}>
          Download for free:
        </span> */}
        <div className='header-download-container'
          onClick={() => {
            // downloadApp('');
          }}
        >
          <img
            style={{
              userSelect: 'none',
              width: getRem(38.4),
              height: getRem(38.4),
            }}
            src={HeadIconApple}
            alt={'App Store'}
          />
          <span style={{
            fontSize: getRem(19),
            color: 'white',
            fontFamily: ranyRegular,
            marginLeft: getRem(10)
          }}>App Store</span>
        </div>
        <div className='header-download-container'
          onClick={() => {
            downloadApp('https://play.google.com/store/apps/details?id=com.picu.app');
          }}
        >
          <img
            style={{
              userSelect: 'none',
              width: getRem(38.4),
              height: getRem(38.4),
            }}
            src={HeadIconGoogle}
            alt={'Google Play'}
          />
          <span style={{
            fontSize: getRem(19),
            color: 'white',
            fontFamily: ranyRegular,
            marginLeft: getRem(10)
          }}>Google Play</span>
        </div>
      </div>
    </AntHeader >
  );
};

export default Header;